@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'breakpoints';

$HEADER_DESKTOP_HEIGHT: 80px;
$HEADER_MOBILE_HEIGHT: 64px;

@layer base {
  html {
    font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    @apply text-black;
    @apply font-light;
    @apply text-base;
  }

  body {
    overflow-x: hidden;
  }
}

@layer utilities {
  .input-validate {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    &.textarea-input {
      @apply relative;
      @apply rounded-lg;
      @apply border-b;
      @apply overflow-hidden;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
      height: 120px;
    }

    &.invalid-input {
      .input-validate__icon {
        &-error {
          @apply flex;
          @apply opacity-100;
          animation: show-error 2s cubic-bezier(0.33, 1, 0.68, 1) forwards;
        }
      }

      & > textarea {
        @apply border-opacity-50;
        @apply border-t-vivid-gamboge;
        @apply border-l-vivid-gamboge;
        @apply border-r-vivid-gamboge;
      }

      & > .input-item {
        @apply border-opacity-50;
        @apply border-vivid-gamboge;
      }
    }

    &.valid-input {
      .input-validate__icon {
        &-success {
          @apply flex;
        }
      }
    }

    &__icon {
      &-error {
        @apply w-5;
        @apply h-5;
        @apply absolute;
        @apply hidden;
        @apply justify-center;
        @apply items-center;
        @apply pointer-events-none;
        @apply opacity-0;
        top: 22px;
        right: 22px;

        &:before {
          @apply content-[""];
          @apply inset-0;
          @apply absolute;
          @apply rounded-full;
          @apply bg-bright-gray;
          transform: scale(0, 0) translate3d(0, 0, 0);
          -webkit-animation: show-error-circle 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0.45s forwards;
          animation: show-error-circle 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0.45s forwards;
        }

        & > .icon {
          @apply w-2.5;
          @apply relative;
          @apply fill-white;
          @apply opacity-0;
          z-index: 1;
          transform: scale(0) translateZ(0);
          -webkit-animation: show-error-inner 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0.45s forwards;
          animation: show-error-inner 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0.45s forwards;
        }
      }

      &-success {
        @apply w-5;
        @apply h-5;
        @apply absolute;
        @apply hidden;
        @apply justify-center;
        @apply items-center;
        @apply pointer-events-none;
        top: 22px;
        right: 22px;

        .icon {
          @apply shrink-0;
          @apply w-5;
          @apply h-5;
          fill: #41dd6d;
          transform: scale(1) translateZ(0);
          -webkit-animation: success-tick 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
          animation: success-tick 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
        }

        .lines {
          @apply block;
          @apply w-9;
          @apply h-9;
          @apply absolute;
          @apply -left-2;
          @apply -top-2;
          stroke-dasharray: 7px;
          stroke-dashoffset: 21px;
          stroke: #41dd6d;
          stroke-width: 1.1px;
          stroke-linecap: round;
          -webkit-animation: success-lines 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0.2s;
          animation: success-lines 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0.2s;
        }
      }
    }

    &.validate-checkbox {
      .checkbox {
        &-container {
          @apply w-6;
          @apply h-6;
          @apply shrink-0;
          @apply relative;
          --checkbox-border-width: 4%;
          --checkbox-border-width-active: 7%;
          --checkbox-color-secondary: #d2d2d2;
          --checkbox-color-primary: #0bb648;

          &.-checked {
            .checkbox-border {
              --checkbox-border-width: var(--checkbox-border-width-active);

              &.-active {
                animation-name: checkedBorderCheckbox;
                animation-duration: 0.5s;
                animation-timing-function: ease-in-out;
                animation-fill-mode: forwards;
              }
            }

            .checkbox-check {
              animation-name: checkedCheckbox;
              animation-duration: 0.5s;
              animation-delay: 0.5s;
              animation-fill-mode: forwards;
              animation-timing-function: ease-in-out;
            }
          }
        }

        &-item {
          @apply opacity-0;
          @apply w-full;
          @apply h-full;
          @apply absolute;
          @apply top-0;
          @apply right-0;
          @apply m-0;
          @apply p-0;
          @apply cursor-pointer;
        }

        &-svg {
          @apply w-full;
          @apply h-full;
        }

        &-check,
        &-border {
          stroke-width: var(--checkbox-border-width);
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        &-border {
          width: calc(100% - (var(--checkbox-border-width) * 2));
          height: calc(100% - (var(--checkbox-border-width) * 2));
          transform: translate(calc(var(--checkbox-border-width) * -1), var(--checkbox-border-width)) rotate(90deg);
          stroke: var(--checkbox-color-secondary);
          transition: 0.2s;
          transform-origin: 50% 50%;

          &.-active {
            @apply transition-none;
            @apply opacity-0;
            stroke-dashoffset: 342;
            stroke-dasharray: 342;
            stroke: var(--checkbox-color-primary);
          }
        }

        &-check {
          stroke-dashoffset: 300;
          stroke-dasharray: 100;
          --checkbox-border-width: var(--checkbox-border-width-active);
          stroke: var(--checkbox-color-primary);
        }
      }
    }
  }

  .error-message {
    @apply text-sm;
    @apply text-red-500;
  }
}

@layer components {
  .banner-section {
    &__btn {
      & > h3 {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        @apply space-x-2;
      }
    }
  }
}

@keyframes show-error {
  0% {
    opacity: 1;
    transform: translate3d(0, 28px, 0);
  }
  33% {
    transform: translate3d(0, -2px, 0);
    opacity: 1;
  }
  66% {
    transform: translate3d(0, 2px, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes show-error-circle {
  33% {
    transform: scale(0.7, 1.1) translate3d(0, 0, 0);
  }
  33%,
  100% {
    background-color: rgba(255, 149, 0, 0.5);
  }
  66% {
    transform: scale(1.05, 0.95) translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    background-color: rgba(255, 149, 0, 0.5);
  }
}

@keyframes show-error-inner {
  100% {
    @apply opacity-100;
    transform: scale(1, 1) translate3d(0, 0, 0);
  }
}

@keyframes checkedBorderCheckbox {
  from {
    @apply opacity-0;
    stroke-dashoffset: 342;
    stroke-dasharray: 342;
  }
  to {
    @apply opacity-100;
    stroke-dashoffset: -307;
    stroke-dasharray: 0.31;
  }
}

@keyframes checkedCheckbox {
  from {
    stroke-dashoffset: 300;
    stroke-dasharray: 100;
  }
  to {
    stroke-dashoffset: 144;
    stroke-dasharray: 84;
  }
}

@keyframes success-tick {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1) translateZ(0);
  }
}

@keyframes success-lines {
  100% {
    stroke-dashoffset: 7px;
  }
}
